import * as React from "react";
import Flatpickr from "react-flatpickr";
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions
import "flatpickr/dist/l10n/pt.js";

const DataPickerComponent = React.forwardRef(
  (
    {
      label,
      onChange,
      placeHolder,
      disabled,
      value,
      minDate,
      maxDate,
      helperText, // Adicionando o helperText como propriedade
      ...props
    },
    ref,
  ) => (
    <Flatpickr
      data-input
      options={{
        enableTime: false,
        locale: "pt",
        dateFormat: "d-m-Y",
        minDate,
        maxDate,
        wrap: true,
      }}
      onChange={onChange}
      disabled={disabled ?? false}
      value={value}
      {...props}
    >
      <div className="br-input">
        <label htmlFor="simple-input">{label}</label>
        <input
          ref={ref}
          id="simple-input"
          type="simple-local"
          placeholder={placeHolder}
          data-input="data-input"
        />
        <button
          data-toggle="1"
          className="br-button circle small"
          type="button"
          aria-label="Abrir Timepicker"
        >
          <i className="fas fa-calendar-alt" aria-hidden="true" />
        </button>
        {helperText && (
          <span className="feedback danger" role="alert">
            <i className="fas fa-times-circle" aria-hidden="true" />
            {helperText}
          </span>
        )}
      </div>
    </Flatpickr>
  ),
);

export default DataPickerComponent;
