import React, { useEffect, useState, useRef, useMemo } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import ToolbarEditor from "../../components/Editor/ToolbarEditor";

import "./TipTapEditor.css";
import PDFpage from "../PDF/PDFPage";
import EditorContext from "../../context/Editor/EditorContext";
import imagePaste from "../../utils/ImagePaste";
import extensions from "../../utils/EditorExtensions";
import GenericService from "../../services/GenericService";
import { useSnackbar } from "../../context/SnackbarProvider";

function Tiptap(props) {
  const [HTMLOutput, setHTMLOutput] = useState([]);
  const [saveStatus, setSaveStatus] = useState(false);
  const [preview, setPreview] = useState(false);
  const [watermarkBase64, setWatermarkBase64] = useState();
  const [idImageSelect, setIdImageSelect] = useState();
  const [enlargeEditor, setEnlargeEditor] = useState(false);
  const [heightEditor, setHeightEditor] = useState(0);
  const { showSnackbar } = useSnackbar();

  const [styleImage, setStyleImage] = useState([
    {
      id: "",
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      },
    },
  ]);

  const {
    handleSaveAutomatic,
    setJsonOutput,
    reportDetails,
    content,
    setContent,
  } = props;

  const hasRun = useRef(false);
  const saveIntervalRef = useRef();

  const autoSave = async (jsonOutput) => {
    try {
      const header = {
        isRascunho: true,
      };
      const response = await GenericService.saveDocument(
        "save-document",
        reportDetails.reportData.id,
        jsonOutput,
        header,
      );
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro ao reucperar relatório: ${error}`,
      });
    }
  };

  useEffect(() => {
    const watermark = async () => {
      try {
        const response = await GenericService.getDocument("user-background");
        if (response.status === 200) {
          setWatermarkBase64(`data:image/png;base64,${response.data}`);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    watermark();
  }, []);

  useEffect(() => {
    let alturaTotalVH;
    if (enlargeEditor) {
      alturaTotalVH = (305.2 * 100) / window.innerHeight;
    } else {
      alturaTotalVH = (396.5 * 100) / window.innerHeight;
    }
    const editorHeight = `${100 - alturaTotalVH}vh`;
    document.documentElement.style.setProperty("--editorHeight", editorHeight);
    setHeightEditor(editorHeight);
  }, [enlargeEditor]);

  const editorRef = useRef();
  const editor = editorRef.current;
  editorRef.current = useEditor({
    extensions: [...extensions],
    content,
    editorProps: {
      attributes: {
        class: "editor table image",
      },
    },
    parseOptions: {
      preserveWhitespace: "full",
    },
    editable: true,
    // triggered on every change
    // eslint-disable-next-line no-shadow
    onUpdate: () => {
      setJsonOutput(editor?.getJSON());
      setHTMLOutput(editor?.getHTML());
    },
    onDestroy: () => {
      const header = document.querySelector(
        "#root > div:nth-child(1) > header",
      );
      if (header) {
        header.style.display = "";
      }
    },
  });

  useEffect(() => {
    if (editor && !hasRun.current) {
      hasRun.current = true;
      if (reportDetails.reportData.status !== "Em elaboração") {
        setPreview(true);
      }
    }
  }, [editor, reportDetails.reportData.status]);

  useEffect(() => {
    if (content && editor) {
      editor?.commands.setContent(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, editor]);

  useEffect(() => {
    if (reportDetails.reportData.status !== "Homologado") {
      saveIntervalRef.current = setInterval(() => {
        setSaveStatus(true);
        handleSaveAutomatic(new Date());
        autoSave(editorRef.current.getJSON());
        setTimeout(() => {
          setSaveStatus(false);
        }, 2000);
      }, 300000);
    }
    return () => {
      clearInterval(saveIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    const header = document.querySelector("#root > div:nth-child(1) > header");
    if (header) {
      if (enlargeEditor) {
        header.style.display = "none";
      } else {
        header.style.display = "";
      }
    }
  }, [enlargeEditor]);

  const handlePaste = (e) => {
    imagePaste(editor);
  };

  const contextValue = useMemo(
    () => ({
      styleImage,
      setStyleImage,
      idImageSelect,
      setIdImageSelect,
    }),
    [styleImage, setStyleImage, idImageSelect, setIdImageSelect],
  );

  return (
    <EditorContext.Provider value={contextValue}>
      <ToolbarEditor
        editor={editor}
        saveStatus={saveStatus}
        preview={preview}
        setPreview={setPreview}
        content={HTMLOutput}
        status={reportDetails.reportData.status}
        enlargeEditor={enlargeEditor}
        setEnlargeEditor={setEnlargeEditor}
      />
      {preview ? (
        <PDFpage editor={editor} waterwark={watermarkBase64} />
      ) : (
        <div id="TipTapEditor" style={{ height: heightEditor }}>
          <EditorContent
            editor={editor}
            style={{
              backgroundImage: `url(${watermarkBase64})`,
              height: heightEditor,
            }}
            onPaste={(e) => handlePaste(e)}
          />
        </div>
      )}
    </EditorContext.Provider>
  );
}

export default Tiptap;
