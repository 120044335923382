import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { format, parseISO } from "date-fns";
import TextArea from "../../components/TextArea/TextArea";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TableComponent from "../../components/Table/TableComponent";
import GenericService from "../../services/GenericService";
import { getUserInfoFromToken } from "../../services/Auth";
import AproveDisaproveRequestComponent from "../../components/HomologComponents/AproveDisaproveRequestComponent";
import ReturnReportModal from "../../components/HomologComponents/ReturnReportModal";
import HomologReportModal from "../../components/HomologComponents/HomologReportModal";
import ReportNotApprovedComponent from "../../components/HomologComponents/ReportNotApprovedComponent";
import CancelRequestHomologate from "../../components/HomologComponents/CancelRequestHomologate";
import { useSnackbar } from "../../context/SnackbarProvider";
import MenuDropdown from "../../components/MenuDropdown/MenuDropdown";

export const reportsDetailsLoader = async ({ params }) => {
  const responseReport = await GenericService.findOne(
    "find-reporting",
    params.id,
  );
  const responseAddons = await GenericService.findAllList(
    `addons/reporting-order/${params.id}`,
  );
  if (responseReport.status === 200 && responseAddons) {
    return {
      reportData: responseReport.data,
      addonsData: responseAddons.data,
    };
  }
  return {};
};

const newAddonFormValidationSchema = yup.object({
  description: yup
    .string()
    .required("O campo ”observação” não pode ficar vazio"),
});

export function formatDescription(description) {
  if (!description) return "";
  const maxLength = 50;
  let formattedDescription = "";

  for (let i = 0; i < description.length; i += maxLength) {
    formattedDescription += `${description.slice(i, i + maxLength)}\n`;
  }

  return formattedDescription;
}

export default function ReportDetails() {
  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(newAddonFormValidationSchema),
    defaultValues: {
      description: "",
    },
  });
  const navigate = useNavigate();
  const [reportDetails, setReportDetails] = useState(
    useLoaderData().reportData,
  );
  const [addOns, setAddOns] = useState(useLoaderData().addonsData);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortInfo, setSortInfo] = useState({ id: "", type: "" });
  const [openModalReturn, setOpenModalReturn] = useState(false);
  const [openModalAprove, setOpenModalAprove] = useState(false);
  const [ordersToHomologate, setOrdersToHomologate] = useState([]);
  const [evaluatedOrders, setEvaluatedOrders] = useState([]);
  const userInfo = getUserInfoFromToken();
  const { showSnackbar } = useSnackbar();

  const getAddons = async (page = 0, size = pageSize) => {
    let query = `page=${page}&size=${size}`;
    if (sortInfo.id && sortInfo.type) {
      const sortId = sortInfo.id === "description" ? "observacao" : sortInfo.id;
      query += `&sortBy=${sortId}&order=${sortInfo.type}`;
    }
    try {
      const response = await GenericService.findAllList(
        `addons/reporting-order/${reportDetails.id}?${query}`,
      );
      if (response.status === 200) {
        setAddOns(response.data);
      }
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro ao pesquisar complementos: ${error}`,
      });
    }
  };

  useEffect(() => {
    getAddons(currentPage, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sortInfo]);

  const handleChangePage = async (page) => {
    setCurrentPage(page);
    await getAddons(page, pageSize);
  };

  const handleChangeSize = async (size) => {
    setPageSize(size);
    setCurrentPage(0);
    await getAddons(0, size);
  };

  const handleSortChange = (sortInfoTable) => {
    const sortId =
      sortInfoTable.id === "description" ? "observacao" : sortInfoTable.id;
    setSortInfo({ id: sortId, type: sortInfoTable.type });
    if (sortInfoTable.type === "") {
      setSortInfo({ id: "", type: "" });
    }
  };

  const handleNewAddon = async (data) => {
    try {
      const payload = {
        ...data,
        active: true,
      };
      const response = await GenericService.create("addOns", payload, {
        reportingOrderId: `${reportDetails.id}`,
      });
      // eslint-disable-next-line no-constant-condition
      if (response.status === 200 || 500) {
        showSnackbar({
          success: true,
          message: "Complemento adicionado com sucesso!",
        });
        await getAddons();
        reset();
      }
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro ao adicionar complemento: ${error}`,
      });
    }
  };

  const handleReport = () => {
    navigate("editar-visualizar", { replace: true });
  };
  const handleHistory = () => {
    // navigate("editar-relatorio", { replace: true });
  };

  const columns = [
    { id: "creationDate", label: "Data", date: true, isSort: true },
    { id: "id", label: "ID", isSort: true },
    {
      id: "description",
      label: "Observação",
      format: formatDescription,
      isSort: true,
    },
  ];

  const optionDropdown = [
    { title: "Editar ordem", icon: "edit", route: "editar-relatorio" },
    { title: "Saldo de horas", icon: "clock", route: "saldo-de-horas" },
  ];

  const closeModalAndReset = (setter) => {
    setter(false);
    reset();
  };

  const details = (title, content, size) => (
    <Grid item xs={size} marginY={1}>
      <Box>
        <Typography variant="p6">{title}</Typography>
      </Box>
      <Box>
        <Typography variant="p">{content}</Typography>
      </Box>
    </Grid>
  );

  const contentArray = [
    {
      title:
        "0000/2023 - Pesquisa por organização criminosa atuante no segmento de produção e distribuição de cervejas",
    },
  ];

  const getReportsDetails = async () => {
    const response = await GenericService.findOne(
      "find-reporting",
      reportDetails.id,
    );
    if (response.status === 200) {
      await setReportDetails(response.data);
    }
  };

  const getOrdersToHomologate = async () => {
    if (
      !userInfo.roles.includes("supervisor") ||
      !userInfo.roles.includes("administrador")
    ) {
      return;
    }
    const response = await GenericService.update("to-homologate");
    setOrdersToHomologate(response.data);
  };

  const handleCancel = async () => {
    try {
      const response = await GenericService.findOne(
        `cancelhomologate`,
        reportDetails.id,
      );
      if (response.status === 200) {
        getReportsDetails();
        showSnackbar({
          success: true,
          message: "Homologação cancelada com sucesso!",
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleAproveDisaproveHomolog = async ({ status, justify, date }) => {
    try {
      const formattedDate =
        date instanceof Date ? date.toISOString().split("Z")[0] : "";

      const orderToHomologate = ordersToHomologate.find(
        (order) => order.order_id === reportDetails.id,
      );
      const idToUse = orderToHomologate
        ? orderToHomologate.id
        : reportDetails.id;

      const response = await GenericService.update(`homologate/avaluating`, {
        id: idToUse,
        justify,
        dateHomologate: formattedDate,
        isAproved: status,
      });

      if (response.status === 200) {
        setOpenModalReturn(false);
        setOpenModalAprove(false);
        showSnackbar({
          success: true,
          message:
            status === true
              ? "Relatório homologado com sucesso!"
              : "Relatório devolvido ao pesquisador!",
          timestamp: true,
        });
        navigate("/relatorios");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getEvaluatedOrders = async () => {
    if (!userInfo?.roles.includes("pesquisador")) {
      return;
    }
    try {
      const response = await GenericService.findAllList(
        "homologatesnotaproved",
      );

      if (response && response.data) {
        let lastMatchingOrder = null;

        // eslint-disable-next-line no-plusplus
        for (let i = response.data.length - 1; i >= 0; i--) {
          const order = response.data[i];
          if (order.orderId === reportDetails.id) {
            lastMatchingOrder = order;
            break;
          }
        }

        if (lastMatchingOrder) {
          setEvaluatedOrders(lastMatchingOrder);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Erro ao buscar pedidos avaliados:", error);
    }
  };

  useEffect(() => {
    getOrdersToHomologate();
    getEvaluatedOrders();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Typography variant="h4" marginY={5}>
          {reportDetails.title}
        </Typography>
        <Box display="flex" gap={2}>
          {(userInfo?.roles?.includes("administrador") ||
            userInfo?.roles?.includes("supervisor")) &&
            reportDetails.status !== "Homologado" && (
              <ButtonComponent
                title="Histórico"
                type="outlined"
                buttonType="secondary"
                icon="history"
                onClick={handleHistory}
              />
            )}
          <ButtonComponent
            title="Ir para o relatório"
            type="contained"
            icon="file"
            onClick={handleReport}
          />
          <MenuDropdown options={optionDropdown} />
        </Box>
      </Box>
      <Grid container display="flex" gap={3}>
        <Grid container xs={8} spacing={3}>
          <Grid container padding={3}>
            {details(
              "Pesquisadores",
              reportDetails?.researcher?.username || "-",
              4,
            )}
            {details(
              "Supervisor",
              reportDetails?.supervisor?.username || "-",
              4,
            )}
            {details("Equipe", reportDetails?.team || "-", 4)}
            {details(
              "Data de início",
              reportDetails?.creationDate
                ? format(parseISO(reportDetails.creationDate), "dd/MM/yyyy")
                : "-",
              2.4,
            )}
            {details(
              "OVR",
              reportDetails?.ovr ? `#${reportDetails.ovr}` : "-",
              2.4,
            )}
            {details("Status", reportDetails?.status || "-", 2.4)}
            {details(
              "Tipo",
              reportDetails?.typeReporting?.description || "-",
              2.4,
            )}
            {details(
              "Data de conclusão",
              reportDetails?.conclusionDate
                ? format(parseISO(reportDetails.conclusionDate), "dd/MM/yyyy")
                : "-",
              2.4,
            )}
          </Grid>
          <Grid container padding={3}>
            <Box marginY={1}>
              <Typography variant="p6">Informações complementares:</Typography>
            </Box>
            <TableComponent
              columns={columns}
              data={addOns}
              changeSize={handleChangeSize}
              changePage={handleChangePage}
              onSortChange={handleSortChange}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          flexDirection="column"
          gap={5}
          alignSelf="flex-start"
        >
          {reportDetails?.status === "Aguardando Homologação" &&
            (userInfo?.roles.some((role) =>
              ["supervisor", "administrador"].includes(role),
            ) ? (
              <AproveDisaproveRequestComponent
                content={reportDetails}
                handleDisaprove={() => setOpenModalReturn(true)}
                handleAprove={() => setOpenModalAprove(true)}
              />
            ) : userInfo?.roles.includes("pesquisador") ? (
              <CancelRequestHomologate
                content={reportDetails}
                handleCancel={() => handleCancel()}
              />
            ) : null)}
          {reportDetails?.status === "Em elaboração" &&
            evaluatedOrders &&
            Object.keys(evaluatedOrders).length > 0 &&
            userInfo?.roles.some((role) => ["pesquisador"].includes(role)) && (
              <ReportNotApprovedComponent
                content={evaluatedOrders}
                report={reportDetails}
              />
            )}

          <form onSubmit={handleSubmit(handleNewAddon)}>
            {reportDetails.status !== "Homologado" && (
              <Box
                boxShadow={2}
                padding={2}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Typography variant="h2">Adicionar complemento</Typography>
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextArea
                      placeholder="Insira sua observação..."
                      maxLength={500}
                      inputHelperText={error?.message}
                      {...field}
                    />
                  )}
                />
                <Box display="flex" justifyContent="flex-end" marginTop={1}>
                  <ButtonComponent title="Salvar e criar" icon="save" submit />
                </Box>
              </Box>
            )}
          </form>
        </Grid>
      </Grid>

      <ReturnReportModal
        content={reportDetails}
        openModal={openModalReturn}
        closeModal={() => closeModalAndReset(setOpenModalReturn)}
        handleReturn={(data) =>
          handleAproveDisaproveHomolog({
            justify: data.reason,
            status: false,
          })
        }
      />

      <HomologReportModal
        openModal={openModalAprove}
        closeModal={() => setOpenModalAprove(false)}
        handleHomolog={(data) => {
          handleAproveDisaproveHomolog({
            date: data["Data inicial"]?.[0],
            status: true,
            justify: "Aprovado para Homologação",
          });
        }}
        content={reportDetails}
      />
    </Box>
  );
}
